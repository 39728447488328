import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import configPlugin from './plugins/config';
// 创建一个新的 Vue 实例用于显示加载状态
const loadingApp = createApp({
    template: '<div>Loading...</div>'
});
loadingApp.mount('#app');

fetch('/config.json?timestamp=' + new Date().getTime())
    .then(response => response.json())
    .then(config => {
        // 删除加载状态的 Vue 实例
        loadingApp.unmount();
        // 创建主应用实例并注入配置
        const app = createApp(App);
        app.use(configPlugin, config);
        app.use(router);
        app.mount('#app');
    })
    .catch(error => {
        console.error('Failed to load config:', error);
    });