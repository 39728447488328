<template>
  <div style="display: flex; justify-content: center">
    <div style="position: relative; max-width: 520px">
      <img className="bag_img" src="@/assets/HsImag/di.png" alt="">
      <div className="conten_box">
        <div className="header_box center_view">
          <img src="@/assets/HsImag/logo.png" alt="">
        </div>
        <div className="center_view">
          <img className="hander_img" src="@/assets/HsImag/icon.png" alt="">
        </div>
        <div className="btn_view center_view">
          <img ref="downloadButton" @click="DownlodClick('Android')" width="50%" src="@/assets/HsImag/anzhuo.png"
               alt="">
          <div id="qrCodeElement"></div>
          <img className="kefu_btn" @click="supperGame" width="15%" src="@/assets/HsImag/kefu_.png" alt="">
        </div>
        <div className="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/1.png" alt="">
        </div>
        <div className="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/2.png" alt="">
        </div>
        <div className="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/50000.png" alt="">
        </div>
        <div className="bomm_img center_view">
          <img width="95%" src="@/assets/HsImag/youxi.png" alt="">
        </div>
        <div className="bomm2_img center_view">
          <img width="95%" src="@/assets/HsImag/shouji.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <noscript>
    <img height="1" width="1" style="display:none"
         src="https://www.facebook.com/tr?id=513771774476040&ev=PageView&noscript=1"/>
  </noscript>
  <noscript>
    <img height="1" width="1" style="display:none"
         src="https://www.facebook.com/tr?id=2227333890947088&ev=PageView&noscript=1"/>
  </noscript>
  <noscript>
    <img height="1" width="1" style="display:none"
         src="https://www.facebook.com/tr?id=1705369716896755&ev=PageView&noscript=1"/>
  </noscript>
</template>


<script setup>
import {getCurrentInstance, onBeforeUnmount, onMounted, ref} from 'vue';
import service from "@/plugins/API";

const clickState = ref(true);
const downloadButton = ref(null);
const getFormattedDate = () => {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
};

const formatTime = (ms) => {
  const seconds = (ms / 1000).toFixed(2);
  const minutes = Math.floor(seconds / 60);
  return `${minutes} m ${seconds % 60} s`;
};

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return Object.fromEntries(params.entries());
};

const sendBeacon = () => {
  const data = {
    channelName: getQueryParams().C,
    changeTime: getFormattedDate(),
    isReview: clickState.value,
  };
  if (clickState.value) {
    service({
      url: '/info/hrefRecord/save',
      method: 'post',
      data,
    });
  }
};

const {appContext} = getCurrentInstance();

const DownlodClick = async () => {
  // eslint-disable-next-line no-undef
  await fbq('trackCustom', 'downloadgame', {country: '1'});
  try {
    clickState.value = false;
    const formattedDate = getFormattedDate();
    const params = getQueryParams();

    await service({
      url: '/info/hrefRecord/save',
      method: 'post',
      data: {
        channelName: params.C,
        changeTime: formattedDate,
        isReview: false,
      },
    });

    const formData = new FormData();
    formData.append('eventID', '215');
    formData.append('modelID', '25');
    formData.append('param1', '用户点击了下载');

    await service({
      url: '/info/event/callback',
      method: 'post',
      data: formData,
    });
  } catch (err) {
    console.error('Download click error:', err);
  }
};

const supperGame = () => {
  window.open(appContext.config.globalProperties.$config.serVer);
};

const handleBeforeUnload = () => {
  sendBeacon();

};

onMounted(() => {
  // 初始化 Facebook Pixel
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  // 确保在 fbq 初始化后调用 fbqInit
  setTimeout(() => {
    let params = getQueryParams();
    if (params.C) {
      const fbqInit = (id) => {
        // eslint-disable-next-line no-undef
        fbq('init', id);
        // eslint-disable-next-line no-undef
        fbq('track', 'PageView');
      };

      switch (params.C) {
        case 'Channel_1':
          fbqInit('513771774476040');
          break;
        case 'Channel_2':
          fbqInit('2227333890947088');
          break;
        case 'Channel_3':
          fbqInit('1705369716896755');
          break;
      }
    }
  }, 500); // 延迟500ms，确保 fbq 脚本已加载

  // 初始化 OpenInstall
  // eslint-disable-next-line no-undef
  const data = OpenInstall.parseUrlParams();
  console.log('OpenInstall data:', data);
  console.log(downloadButton)
  // eslint-disable-next-line no-undef
  new OpenInstall({
    appKey: 'ui5gre',
    onready: function () { // 初始化成功回调方法。当初始化完成后，会自动进入
      this.schemeWakeup(); // 尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
      var m = this; // 保存当前上下文
      // 确保图片元素存在后再绑定事件
      if (downloadButton.value) {
        downloadButton.value.onclick = function () { // 为图片绑定点击事件
          m.wakeupOrInstall(); // 此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
          return false; // 阻止默认事件
        }
      }
    }
  }, data);

  // 发送事件数据
  const sendEventCallback = (eventID, param1) => {
    const formData = new FormData();
    formData.append('eventID', eventID);
    formData.append('modelID', '25');
    formData.append('param1', param1);

    return service({
      url: '/info/event/callback',
      method: 'post',
      data: formData,
    });
  };

  sendEventCallback('213', formatTime(performance.now()));
  sendEventCallback('212', '1');
  sendEventCallback('214', formatTime(performance.now()));

  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});


</script>


<style scoped>
.bag_img {
  background: url("@/assets/HsImag/di.png");
  width: 100%;
  height: 100%;
}

.conten_box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header_box img {
  width: 100%;
  height: 100%;
}

.center_view {
  display: flex;
  justify-content: center;
}

.hander_img {
  width: 35%;
}

.kefu_btn {
  right: 2%;
  position: absolute;
  top: -55%
}

.btn_view {
  position: relative;
}

.bomm_img {
  z-index: 10;
  position: sticky;
}

.bomm2_img {
  position: absolute;
  bottom: -24%;
  z-index: 1;
}
</style>
