import axios from "axios";
const baseUrl = "/api"
      const service = axios.create({
            baseURL: baseUrl, // api的base_url
            timeout: 5000 // 请求超时时间
      })
// service.interceptors.request.use(
//     config => {
//           // 在发送请求之前做些什么
//           // 例如，设置token
//
//           return config;
//     },
//     error => {
//           // 对请求错误做些什么
//           console.error('请求出错：', error); // for debug
//           Promise.reject(error);
//     }
// );
//
// // 响应拦截器
// service.interceptors.response.use(
//     response => {
//           /**
//            * 对响应数据做点什么
//            * 例如，根据状态码进行错误处理
//            */
//           const res = response.data;
//           if (res.code !== 200) {
//                 // 处理错误
//                 console.error('接口错误：', res.message);
//                 return Promise.reject(new Error(res.message || 'Error'));
//           } else {
//                 return res;
//           }
//     },
//     error => {
//         console.log(error)
//           console.error('响应出错：', error); // for debug
//           return Promise.reject(error);
//     }
// );

export default service;